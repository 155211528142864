// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adv-js": () => import("./../../../src/pages/adv.js" /* webpackChunkName: "component---src-pages-adv-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-independent-js": () => import("./../../../src/pages/independent.js" /* webpackChunkName: "component---src-pages-independent-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-one-stop-js": () => import("./../../../src/pages/oneStop.js" /* webpackChunkName: "component---src-pages-one-stop-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-saas-js": () => import("./../../../src/pages/saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-termsof-service-js": () => import("./../../../src/pages/termsofService.js" /* webpackChunkName: "component---src-pages-termsof-service-js" */),
  "component---src-pages-termsof-use-js": () => import("./../../../src/pages/termsofUse.js" /* webpackChunkName: "component---src-pages-termsof-use-js" */)
}

